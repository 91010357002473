
import { copyLink } from "@/utils/global";
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ReferralEmpty",
  data() {
    return {
      elements: [
        `Когда пользователь зарегистрировался по вашей партнерской ссылке и пополнил партнерский баланс, вы получаете <span>25%</span> от суммы пополнения.\n\n
        Если друг привел другого пользователя и он оплатит, то вы заработаете <span>10%</span>`,
        `Пользователь закрепляется за вами навсегда, когда он зарегистрировался по вашей партнерской ссылке, и вы получаете свою комиссию <span>с каждой оплаты</span> пользователей.`,
        `Если пользователь перешел по ссылке, но не зарегистрировался, а сделал это 
        с другого компьютера или из другого браузера, зайдя на сайт Botpay.me напрямую, <span>регистрация учтена не будет</span>`,
      ],
    };
  },
  computed: mapGetters(['user']),
  methods: {
    copyLink() {
      copyLink(`${process.env.VUE_APP_URL}/auth?partner=` + this.user.partner.code);
      this.$store.commit('createGeneralNotification', {
        message: 'Ваша партнёрская ссылка скопирована в буфер обмена!',
        status: 'success'
      })
    }
  }
});
