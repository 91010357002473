
import { defineComponent } from "vue-demi";
import { PARTNER_TABLE_OPTIONS } from "@/store/common/default/table";
import { useAPI } from "@/use";
import CommonTable from '@/components/common/table/index.vue';
import PartnerEmpty from './Empty.vue';

export default defineComponent({
  name: 'PartnerList',
  data() {
    return {
      partners: PARTNER_TABLE_OPTIONS(),
      count: null as number | null
    }
  },
  created() {
    this.getCountMethod();
  },
  methods: {
    async getCountMethod() {
      const result = await useAPI().partner.getCountMethod();
      this.count = result.data.total;
    }
  },
  components: {
    CommonTable,
    PartnerEmpty
  }
})
