import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "partner" }
const _hoisted_2 = {
  key: 0,
  class: "partner--container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_table = _resolveComponent("common-table")!
  const _component_partner_empty = _resolveComponent("partner-empty")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.count !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!!_ctx.count)
            ? (_openBlock(), _createBlock(_component_common_table, {
                key: 0,
                options: _ctx.partners
              }, null, 8, ["options"]))
            : (_openBlock(), _createBlock(_component_partner_empty, { key: 1 }))
        ]))
      : _createCommentVNode("", true)
  ]))
}