
import { defineComponent } from "vue-demi";
import Partner from "@/components/partner/List.vue";

export default defineComponent({
  name: "PartnerListView",
  data() {
    return {
      partners: [],
    }
  },
  created() {
    this.getListMethod();
  },
  methods: {
    async getListMethod() {
      // const result = await useAPI().partner.getListMethod();
      return {};
    }
  },
  components: {
    Partner,
  },
});
